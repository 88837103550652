
import { Component, Prop, Vue } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { getConfigEnv, sleep } from '@/utils/helpers';
import { DateTime } from 'luxon';
import axiosClient from '@/lib/rest/axiosClient';
import gptModule from '@/store/modules/gptModule';

@Component({
  components: {
    Multiselect,
  },
})
export default class TasqLastActionDetails extends Vue {
  @Prop({ type: Object, required: true }) tasq?: any;

  @Prop({ type: Object, required: true }) lastAction?: any;

  body = '';

  startDate = '';

  endDate = '';

  reason = '';

  title = '';

  isSavingData = false;

  created() {
    console.log(this.lastAction);
    this.startDate = this.lastAction.date;
    this.endDate = this.lastAction.date;
    this.body = this.lastAction.body;
    this.reason = this.lastAction.summary;
    this.title = this.lastAction.title;
    this.endDate = this.lastAction.endDate;

  }

  mounted() {
    this.resizeTextarea('textarea1'); // Resize on component mount for textarea1
        this.resizeTextarea('textarea2');
  }

  get disabledDates() {
    return [
      (date) => {
        const startDate = new Date(this.startDate);
        const endDate = DateTime.fromISO(date.toISOString());
        let daysDiff: any = DateTime.local()
          .diff(endDate, 'days')
          .toObject()
          ?.days?.toFixed();
        startDate.setHours(0, 0, 0, 0);
        date.setHours(0, 0, 0, 0);
        return date <= startDate || daysDiff > 3;
      },
    ];
  }

  get isValidForm() {
    if (!this.startDate) {
      return false;
    }
    if (!this.reason) {
      return false;
    }

    if (!this.lastAction.id) {
      return false;
    }

    return true;
  }

  get operatorList() {
    return [
      { operatorID: 8, operatorName: 'petronas', appName: 'petronascanada' },
      { operatorID: 7, operatorName: 'taprock', appName: 'taprock' },
      { operatorID: 6, operatorName: 'pdce', appName: 'pdc' },
      { operatorID: 2, operatorName: 'SWN', appName: 'swn' },
      { operatorID: 1, operatorName: 'Extraction', appName: 'civitas' },
      { operatorID: 9, operatorName: 'demo', appName: 'demo' },
      { operatorID: 5, operatorName: 'caerus', appName: 'caerus' },
    ];
  }

  get operatorName() {
    return getConfigEnv('OPERATOR_LOWERCASED');
  }

  get loading(){
    return gptModule.isLoadingActions
  }

  async updateAiComment() {
    try {
      gptModule.setActionsLoading(true);
      const operatorDetails: any = this.operatorList.find(
        (o) => o['appName'] === this.operatorName
      );

      const metaData = {
        useAll: false,
        wellMetaData: {
          ...(this.operatorName !== 'demo' && {
            operator_name: operatorDetails['operatorName'],
          }),
          ...(this.operatorName !== 'demo' && {
            operator_id: operatorDetails['operatorID'],
          }),
          nodeid: this.tasq.wellName,
        },
      };

      const payload = {
        query: '',
        stream: false,
        ...metaData,
      };
      const response = await axiosClient.post('/ai/vector', {
        id: this.lastAction.id,
        body: this.body,
        summary: this.reason,
      });
      console.log('Response for', 'ai/vector first', ':', response.data);
      console.log(payload);
      await gptModule.getGptLastActions(payload);
      this.$tasqAlert({
        title: 'Success',
        message: 'Last action successfully updated',
        type: 'success',
      });
    } catch (e) {
      console.log(e);
      this.$tasqAlert({
        title: 'Success',
        message: 'Last action update failed',
        type: 'error',
      });
    } finally {
      gptModule.setActionsLoading(false);
      this.$emit('close');
    }
  }

  updateListText(e) {
    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  }

  resizeTextarea(refName) {
            const textarea: any = this.$refs[refName];
            textarea.style.height = 'auto'; // Reset the height first
            textarea.style.height = (textarea.scrollHeight) + 'px';
        }
}
